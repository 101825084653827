<template>
	<div id="app">

		<Head v-if="!_isMobile()" />
		<HeadM v-if="_isMobile()" />


		<!-- <router-view :class="$route.meta.appname.toLowerCase()" /> -->

		<keep-alive>
			<router-view transition-mode="out-in" ref="routViewKeep" v-if="this.$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view transition-mode="out-in" ref="routView" v-if="!this.$route.meta.keepAlive"></router-view>


		<Foot />
		<Reserve />

		<toast ref="toast" v-model="$store.state.showToast" :timmer="$store.state.toastTimer" :toast-txt="$store.state.toastTxt"></toast>

	</div>
</template>


<script>
import '@/css/home/common.scss'
import '@/css/home/pc/pc.scss'
import '@/css/home/mobile/mobile.scss'
import toast from '@/components/toast.vue'
import Head from './views/pc/head.vue'
import HeadM from './views/mobile/head.vue'
import Foot from './views/foot.vue'
import Reserve from './views/reserve.vue'
import ClientOnly from "vue-client-only";

export default {
	name: "app",
	components: {
		ClientOnly,
		toast,
		Head,
		HeadM,
		Foot,
		Reserve,
	},
	data() {
		return {
			myApp: null,
			screenWidth: null,
			is_mobile: null,
		};
	},
	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				console.log("ssr");
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	created() {
	
	},
	mounted() {
		this.getBasic()
		console.log(process.env);
		this.$logEvent("show_movie_home");
	
	},

	methods: {
		getBasic() {
			const options = {
				method: 'GET',
				headers: {
					accept: 'application/json',
					Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg',
				},
			}

			fetch('https://api.themoviedb.org/3/configuration', options)
				.then((response) => response.json())
				.then((response) => {
					console.log(response)
					this.$store.commit('UPDATE_BASEURL',`${response.images.base_url}`)
					this.$store.commit('UPDATE_POSTERSIZE',response.images.poster_sizes)
					this.$store.commit('UPDATE_PROFILESIZE',response.images.profile_sizes)
					console.log(response.images.poster_sizes);
					this.$store.dispatch("initHomeData", "success");
				})
				.catch((err) => console.error(err))
		},
		_isMobile() {
			if (typeof navigator !== "undefined") {
				// 在浏览器环境中执行的代码
				// 使用 navigator 对象进行相关操作
				return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				);
			} else {
				// 在服务器环境中执行的代码
				// 忽略或提供替代方案
				return false;
			}
		},
	},
};
</script>
