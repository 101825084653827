<template>
	<div class="small-poster-item" @click="gotoDetail(postdetail.id,media_type)" v-if="postdetail">
		<img v-if="$store.state.home.base_url" :src="`${$store.state.home.base_url}${$store.state.home.poster_size[1]}${postdetail.poster_path}`" class="small-poster-item-img" alt="" />
		<div class="poster-title" >{{ media_type=='tv'?postdetail.name:postdetail.title }}</div>
		<div class="poster-update-time">
			{{ media_type=='tv'?postdetail.first_air_date:postdetail.release_date }} 
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			media_type: null
		}
	},
	props: {
		appname: {
			default: ' ',
		},
		postdetail: {
			require: true,
		},
		isTV: {
			default: false,
		},
	},
	created() {
		var postmediatype = this.postdetail.media_type
		if(this.isTV || postmediatype =="tv"){
			this.media_type = 'tv'
		}
	},
	methods: {
		gotoDetail(params,media_type) {
			this.$router.push({
				name: 'detail',
				query: {
					poster_id: params,
					media_type: media_type
				},
			})
		},
	},
}
</script>
