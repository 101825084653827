import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		showToast: false,
		toastTxt: '',
		toastTimer: 2000,

		activeNav: 0,
		popup: false,
		breadNav: false,

		base_url: null,
		poster_size: null,
		profile_size: null,


	},

})

const mutations = {
	// 调用方法：commit('home/xxx','xxx')
	UPDATE_ACTIVE_NAV: (state, string) => {
		state.activeNav = string
	},
	UPDATE_POPUP_STATUS: (state, boolean) => {
		state.popup = boolean
	},
	UPDATE_BREAD_NAV: (state, boolean) => {
		state.breadNav = boolean
	},
	UPDATE_BASEURL: (state, url) => {
		state.base_url = url
	},
	UPDATE_POSTERSIZE: (state, obj) => {
		console.log(obj);
		state.poster_size = obj
	},
	UPDATE_PROFILESIZE: (state, obj) => {
		console.log(obj);
		state.profile_size = obj
	},
}

export default {store,mutations}
