import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
// 把 Vue Router 当前的 $route 同步为 Vuex 状态的一部分
import { sync } from 'vuex-router-sync'
import global from '@/utlis/global'
import UAParser from 'ua-parser-js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
const firebaseConfig = {
	apiKey: 'AIzaSyBxC0TzD4pAobtpzI7qS8d_syW-MonjMvI',
	authDomain: 'movietvshow-c57df.firebaseapp.com',
	projectId: 'movietvshow-c57df',
	storageBucket: 'movietvshow-c57df.appspot.com',
	messagingSenderId: '197320703089',
	appId: '1:197320703089:web:d77a9029d5cf039a25afd0',
	measurementId: 'G-01GFFDQ9XG',
}
// Initialize Firebase
let app = null
let analytics = null

// console.log(navigator.userAgent);

const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'

// console.log(deviceInfo, isMobileDevice)
isSupported().then((result) => {
	if (result) {
		app = initializeApp(firebaseConfig)
		analytics = getAnalytics(app)

		logEvent(analytics, 'in_page')
	}
})

// simple event

Vue.prototype.$global = global
Vue.prototype.$server = global.server
Vue.prototype.$ua = {
	deviceInfo,
	isMobileDevice,
}
Vue.prototype.$logEvent = (event, params = {}) => {
	console.log(event)
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, event, params)
		}
	})
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	// 同步路由状态(route state)到 store
	sync(store, router)
	const app = new Vue({
		router,
		store,
		data() {
			return {
				isMobileDevice: isMobileDevice,
			}
		},

		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
