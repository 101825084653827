import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			count: 1000,
			voteScore: null,
			homedata: null,
			showToast: false,
			toastTxt: '',
			toastTimer: 2000,
		},
		modules,
		mutations: {
			UPDATE_VOTING(state, num) {
				state.voteScore = num
			},
			setTargetValue(state, value) {
				state.homedata = value
			},
		},
		actions: {
			initHomeData({ commit }, value) {
				// 执行一些初始化操作，然后设置状态
				// 这里只是演示，你可以根据实际需求进行操作
				// 比如从 API 获取数据等
				setTimeout(() => {
					commit('setTargetValue', value)
				}, 1000)
			},
		},
	})
}
