<template>
	<section class="foot flex-col align-center">
		<div class="logo-row">
			<img src="@/assets/home/logofoot.png" class="foot-logo" alt="" />
		</div>
		<div class="contact flex-col align-center">
			<div class="contact-title">CONTACT</div>
			<div class="contact-mail">savein.flix@gmail.com</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {},
}
</script>
