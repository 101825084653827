<template>
	<section class="head-row">
		<div class="head">
			<div class="logo-row">
				<img src="@/assets/home/logo@2x.png" class="head-logo" alt="" @click="gohome()" />
				<div class="head-btn-row">
					<div class="head-btn-item" @click="changeNav(1)" :class="{ active: $store.state.home.activeNav == 1 }">Movies</div>
					<div class="head-btn-item" @click="changeNav(2)" :class="{ active: $store.state.home.activeNav == 2 }">TV Shows</div>
				</div>
			</div>
			<div class="join-n-search">
				<div class="join-btn">Join SAVEiN</div>
				<!-- <div class="search" @click="showSearch()" :class="{ active: showSearchArea }"></div> -->
			</div>
		</div>
		<div class="search-area" v-show="showSearchArea">
			<div class="search-input-row">
				<input type="text" v-model="searchMovie" placeholder="Search for a movie, TV Show…" name="" class="search-input" id="search-input" />
				<div class="close-icon" @click="emptyInput()"></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		appname: {
			default: ' ',
		},
	},
	data() {
		return {
			showSearchArea: false,
			searchMovie: '',
		}
	},
	methods: {
		showSearch() {
			this.showSearchArea = !this.showSearchArea
			if (!this.showSearchArea) {
				this.searchMovie = ''
			}
		},
		emptyInput() {
			this.searchMovie = ''
		},
		gohome() {
			this.$store.commit('UPDATE_ACTIVE_NAV', 0)
			if (this.$route.name != 'Home') {
				this.$router.push({ name: 'Home' })
			}
		},
		changeNav(nav) {
			const navRoutes = {
				1: () => this.$router.push({ path: '/movies' }),
				2: () => this.$router.push({ path: '/tvs' }),
			}

			this.$store.commit('UPDATE_ACTIVE_NAV', nav)

			navRoutes[nav]?.()
		},
		close() {
			this.$emit('input', false)
		},
		autoClick(status) {
			this.showAuto = false
			clearTimeout(this.timer)
			if (status == 1) {
				this.$emit('funcFromChild', {
					func: 'download',
					query: {
						downloadfrom: 'autodownload_popup_btn',
					},
				})
			}
			this.$emit('funcFromChild', {
				func: 'report',
				query: {
					time: new Date(),
					message: 'autodownload_popup_btn_click',
					method: status ? 'continue' : 'cancel',
				},
			})
		},
		popOrDownload() {
			let _this = this
			if (this.autopop) {
				this.timer = setInterval(() => {
					_this.showAuto = true
					if (_this.countdownTimmer <= 1) {
						_this.$emit('funcFromChild', {
							func: 'download',
							query: {
								downloadfrom: 'autodownload_timmer_end',
							},
						})

						_this.showAuto = false
						_this.$emit('input', false)
						clearInterval(_this.timer)
						return
					}
					_this.countdownTimmer--
				}, 1000)
			} else {
				this.$emit('funcFromChild', {
					func: 'download',
					query: {
						downloadfrom: 'schema_fail',
					},
				})
			}

			this.$emit('funcFromChild', {
				func: 'report',
				query: {
					time: new Date(),
					message: `open_${_this.appname}_schema`,
					method: 'fail',
				},
			})
		},
		appDownload() {
			let _this = this
			function failed() {
				console.log('fail')
				_this.popOrDownload()
			}

			function transfer(cb) {
				window.location.href = _this.deepLinkUrl
				const initialTime = new Date()
				let counter = 0
				let waitTime = 0
				const checkOpen = setInterval(() => {
					counter++
					waitTime = new Date() - initialTime
					if (waitTime > 3500) {
						clearInterval(checkOpen)
						cb()
					}
					if (counter < 1000) {
						return
					}
				}, 20)

				document.addEventListener('visibilitychange', () => {
					const isHidden = document.hidden
					if (isHidden) {
						clearInterval(checkOpen)
					}
				})

				_this.$emit('funcFromChild', {
					func: 'report',
					query: {
						time: new Date(),
						message: `open_${_this.appname}_schema`, //统计 open的uv 统计fail的uv  相减就是成功的uv
						method: 'open',
					},
				})
			}
			// transfer(failed);

			if (this.deepLinkUrl) {
				transfer(failed)
			} else {
				console.log('no deeplink')
				failed()
			}
		},
	},
}
</script>
