<template>
	<section class="reserve flex-row align-center justify-center">© 2023 movietvshow.online All Right Reserved</section>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		appname: {
			default: ' ',
		},
	},
	methods: {},
}
</script>
