<template>
	<section class="home mm">
		<div class="banner-container" :style="{ backgroundImage: backgroundImage }">
			<div class="banner-nav-row">
				<div class="banner-nav-title">Latest Trailers</div>
				<div class="banner-nav-items active">Now Playing</div>
				<!-- <div class="banner-nav-items">On TV</div>
				<div class="banner-nav-items">For Rent</div>
				<div class="banner-nav-items">In Theaters</div> -->
			</div>

			<div class="banner-poster-row" v-if="nowplaying && $store.state.home.base_url">
				<div class="banner-poster-item" v-for="(data,i) in nowplaying" :key="i" @mouseover="changeBackgroundImage(`${$store.state.home.base_url}${$store.state.home.poster_size[$store.state.home.poster_size.length - 1]}${data.poster_path}`)" @click="gotoDetail(data.id,data.media_type)">
					<div class="banner-poster-play-row">
						<img :src="`${$store.state.home.base_url}${$store.state.home.poster_size[2]}${data.poster_path}`" alt="" />
						<div class="play-btn"></div>
					</div>
					<div class="banner-poster-content-row">
						<div class="banner-poster-name">{{ data.title }}</div>
						<div class="banner-poster-description">{{ data.overview }}</div>
					</div>
				</div>
			</div>
			<div class="black-shadow"></div>

		</div>

		<div class="trending-container plate-cotainer">
			<div class="white-shadow" ref="trendPosterShadow"></div>
			<div class="plate-title-row">
				<div class="trending-title plate-title" @click="showpopup()">Trending</div>
			</div>
			<div class="plate-poster-row" ref="trendPoster" @scroll="handleScroll('trendPoster','trendPosterShadow')">
				<poster v-for="(data,i) in trendingAll" :postdetail="data" :key="i"></poster>
			</div>
		</div>

		<div class="popular-container plate-cotainer">
			<div class="white-shadow" ref="poplarPosterShadow"></div>
			<div class="plate-title-row">
				<div class="trending-title plate-title">What’s Poplar</div>
				<div class="plate-sub-nav-row">
					<div class="plate-sub-nav-item" @click="changePopular(1)" :class="{'active':activePopular == 1}">Movie</div>
					<div class="plate-sub-nav-item" @click="changePopular(2)" :class="{'active':activePopular == 2}">TV SERIES</div>
					<!-- <div class="plate-sub-nav-item">For Rent</div> -->
					<!-- <div class="plate-sub-nav-item">In Theaters</div> -->
				</div>
			</div>
			<div class="plate-poster-row" v-if="popularList" ref="poplarPoster" @scroll="handleScroll('poplarPoster','poplarPosterShadow')">
				<poster v-for="(data,i) in popularList" :isTV="activePopular==2" :postdetail="data" :key="i"></poster>
			</div>
		</div>
	</section>
</template>

<script>
import poster from "./poster.vue";
import { mapState } from "vuex";

export default {
	data() {
		return {
			backgroundImage: "",
			trendingAll: null,
			nowplaying: null,
			popularList: null,

			activePopular: 1,
		};
	},
	components: {
		poster,
	},
	computed: {
		...mapState({
			targetValue: (state) => state.homedata,
		}),
	},

	beforeMount() {
		if (this.$store.state.homedata == "success") {
			this.getTrending();
			this.getNowPlaying();
			this.getPopularMovie();
		}
		this.$store.watch(
			(state, getters) => state.homedata,
			(newVal, oldVal) => {
				// 在这里处理状态变化
				console.log("targetValue 已经设置为", newVal);
				this.getTrending();
				this.getNowPlaying();
				this.getPopularMovie();
			}
		);
	},
	methods: {
		changeBackgroundImage(imageUrl) {
			this.backgroundImage = `url(${imageUrl})`;
		},
		gotoDetail(params,media_type) {
			this.$router.push({
				path: '/detail',
				query: {
					poster_id: params,
					media_type: media_type
				},
			})
		},
		showpopup() {
			this.$store.commit("UPDATE_POPUP_STATUS", true);
		},
		changePopular(pop) {
			if (this.activePopular == pop) return;
			this.activePopular = pop;
			if (pop == 1) {
				this.getPopularMovie();
			}
			if (pop == 2) {
				this.getPopularTV();
			}
		},
		handleScroll(refs, changClass) {
			const father = this.$refs[refs];
			const change = this.$refs[changClass];
			console.log(
				father.scrollLeft,
				father.clientWidth,
				father.scrollWidth - 10
			);

			if (
				father.scrollLeft + father.clientWidth >=
				father.scrollWidth - 10
			) {
				change.classList.add("end");
			} else {
				change.classList.remove("end");
			}
		},
		getTrending() {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				"https://api.themoviedb.org/3/trending/all/week?language=en-US",
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.trendingAll = response.results;
				})
				.catch((err) => console.error(err));
		},
		getNowPlaying() {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				"https://api.themoviedb.org/3/movie/now_playing?language=en-US&page=1",
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.nowplaying = response.results;
					console.log(this.$store.state.home);
					var backgroundImage = `${this.$store.state.home.base_url}${this.$store.state.home.poster_size[3]}${response.results[0].poster_path}`;

					this.changeBackgroundImage(backgroundImage);
				})
				.catch((err) => console.error(err));
		},
		getPopularMovie() {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};
			this.popularList = null;

			fetch(
				"https://api.themoviedb.org/3/movie/popular?language=en-US&page=1",
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.popularList = response.results;
				})
				.catch((err) => console.error(err));
		},
		getPopularTV() {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};
			this.popularList = null;

			fetch(
				"https://api.themoviedb.org/3/tv/popular?language=en-US&page=1",
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.popularList = response.results;
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>
