import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeM from '../views/mobile/home.vue'
import Home from '../views/pc/home.vue'
import UAParser from 'ua-parser-js'
const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta:{
			keepAlive: true, // 需要缓存
		}
		// component: () => import('../views/gb/pc.vue'),
	},
	{
		path: '/movies',
		name: 'movies',
		meta: {
			keepAlive: true, // 需要缓存
		},
		component: () => import('../views/pc/movie.vue'),
	},
	{
		path: '/tvs',
		name: 'tv',
		meta: {
			keepAlive: true, // 需要缓存
		},
		component: () => import('../views/pc/tv.vue'),
	},
	{
		path: '/detail',
		name: 'detail',
		meta: {
			keepAlive: false, // 需要缓存
		},
		component: () => import('../views/pc/media-detail.vue'),
	},
	{
		path: '/cast',
		name: 'cast',
		meta: {
			keepAlive: true, // 需要缓存
		},
		component: () => import('../views/pc/cast.vue'),
	},
	{
		path: '/result',
		name: 'result',
		meta: {
			keepAlive: true, // 需要缓存
		},
		component: () => import('../views/pc/search-result.vue'),
	},

]

export const constRoutesM = [
	{
		path: '/',
		name: 'Home',
		component: HomeM,
		meta:{
			keepAlive: true, // 需要缓存
		}
		// component: () => import('../views/gb/pc.vue'),
	},
	{
		path: '/detail',
		name: 'detailm',
		meta: {
			keepAlive: false, // 需要缓存
		},
		component: () => import('../views/mobile/media-detail.vue'),
	},
	{
		path: '/cast',
		name: 'castm',
		meta: {
			keepAlive: true, // 需要缓存
		},
		component: () => import('../views/mobile/cast.vue'),
	},
	{
		path: '/movies',
		name: 'moviesm',
		meta: {
			keepAlive: false, // 需要缓存
		},
		component: () => import('../views/mobile/movie.vue'),
	},
	{
		path: '/tvs',
		name: 'tvm',
		meta: {
			keepAlive: false, // 需要缓存
		},
		component: () => import('../views/mobile/tv.vue'),
	},
]

var routes = []
if (isMobileDevice) {
	routes = constRoutesM
}
if (!isMobileDevice) {
	routes = constRoutesPc
}

// routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
